import React from 'react';
import PropTypes from 'prop-types';

const CustomIcon = ({ children }) => (
    <i className="icon">
        {children}
    </i>
);

CustomIcon.propTypes = {
    children: PropTypes.element.isRequired,
};

export default CustomIcon;
