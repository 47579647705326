import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import queryString from 'querystring';
import {
    graphql,
    StaticQuery,
} from 'gatsby';
import {
    Icon,
    Menu,
    Sidebar,
} from 'semantic-ui-react';
import 'typeface-nunito-sans';

import '../semantic/semantic.less';
import Footer from './footer';
import MegaMenuDesktop from './MegaMenu/desktop';
import MegaMenuMobile from './MegaMenu/mobile';
import Helmet from './Helmet';

import Logo from '!svg-react-loader!../images/logos/logo-debtconsolidation.svg?name=Logo';


class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: 'default',
            menuVisible: false,
        };
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
    }

    recordClickEvent = (jwt) => {
        if (typeof sessionStorage === 'undefined') {
            return;
        }
        const query = queryString.parse(window.location.search.replace(/^\?/, ''));
        const headers = {};
        if (jwt) {
            headers.Authorization = `Bearer ${jwt}`;
        }
        axios.post('/api/event', {
            name: 'click',
            uri: window.location.toString(),
            source: query.utm_source || null,
            medium: query.utm_medium || null,
        }, {
            headers,
        }).then(() => {
            sessionStorage.setItem('utms', JSON.stringify(query));
        }).catch((err) => {
            console.error(err);
        });
    };

    handleItemClick(e, { name }) {
        this.setState({ activeItem: this.state.activeItem === name ? 'default' : name });
    }

    handleToggleMenu() {
        this.setState({ menuVisible: !this.state.menuVisible });
    }

    render() {
        const { children, showDisclosures, showInformationDisclosure } = this.props;
        const { menuVisible } = this.state;
        return (
            <StaticQuery
                query={graphql`
                    query StaticLayoutQuery {
                        site {
                            siteMetadata {
                                tagline
                                name
                                description
                            }
                        }
                    }
                `}
                render={data => (
                    <>
                        <Helmet
                            title={(data.site.siteMetadata.tagline + ' - ' + data.site.siteMetadata.name)}
                            description={data.site.siteMetadata.description}
                        />

                        <MegaMenuMobile
                            menuVisible={menuVisible}
                            handleToggleMenu={this.handleToggleMenu}
                        />

                        <Sidebar.Pusher dimmed={menuVisible}>
                            <nav className="megamenu">
                                <MegaMenuDesktop />
                                <div className='megamenu__mobile phonenumber'>
                                    <a href="tel:+18668920226">Talk to a Debt Pro 866-892-0226</a>
                                </div>
                                <Menu className="megamenu__mobile">
                                    <Menu.Item className="logo">
                                        <a href="/" title="Home"><Logo /></a>
                                    </Menu.Item>
                                    <Menu.Menu position="right">
                                        <Icon name="bars" size="large" color="blue" onClick={this.handleToggleMenu} />
                                    </Menu.Menu>
                                </Menu>
                            </nav>
                        </Sidebar.Pusher>

                        {children}
                        
                        <Footer
                            showDisclosures={showDisclosures}
                            showInformationDisclosure={showInformationDisclosure}
                        />
                    </>
                )}
            />
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    showDisclosures: PropTypes.bool,
    showInformationDisclosure: PropTypes.bool,
};

Layout.defaultProps = {
    showDisclosures: true,
};

export default Layout;
