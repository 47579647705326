import React from 'react';
import { List } from 'semantic-ui-react';


const InformationWarrantyDisclosure = () => {
    return (
        <List size="mini">
            <List.Item>
                <List.Content>
                    <List.Description>
                        <strong>Information Warranty &amp; Disclosure:</strong> Great efforts are made to maintain reliable data on all offers presented. However, users should check each provider’s official website for updated terms, details and conditions for each offer before applying or signing up. Our site maintains strict terms of service and may accept compensation for paid ads or sponsored placements in accordance with these terms. Users must be at least 18 years of age to be eligible for financial offers as per the terms presented on provider websites.
                    </List.Description>
                </List.Content>
            </List.Item>
        </List>
    );
};

export default InformationWarrantyDisclosure;