import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
    Container,
    Divider,
    Grid,
    List,
    Menu,
    Segment,
} from 'semantic-ui-react';

import CustomIcon from './CustomIcon';
import Logo from '!svg-react-loader!../images/logos/logo-debtconsolidation-white.svg?name=Logo';
import FacebookSVG from '!svg-react-loader!../images/svg-icons/social/facebook-white.svg';
import TwitterSVG from '!svg-react-loader!../images/svg-icons/social/twitter-white.svg';
import PinterestSVG from '!svg-react-loader!../images/svg-icons/social/pinterest-white.svg';
import YouTubeSVG from '!svg-react-loader!../images/svg-icons/social/youtube-white.svg';

import InformationWarrantyDisclosure from '../components/Disclosures/InformationWarranty';
import EditorialNote from '../components/Disclosures/EditorialNote';
import AdvertiserDisclosure from '../components/Disclosures/Advertiser';

const SocialLink = styled.a`
    margin-left: 1rem;
    
    &:first-child {
        margin: 0;
    }
    
    .icon {
        font-size: 1.2rem;
    }
`;

const Footer = ({ showDisclosures, showInformationDisclosure }) => (
    <>
        {showDisclosures ? (
            <Container fluid className="disclosures">
                <Divider />
                <Container>
                    {showInformationDisclosure && (
                        <InformationWarrantyDisclosure />
                    )}
                    <EditorialNote />
                    <AdvertiserDisclosure />
                </Container>
            </Container>
        ) : (
            <Divider hidden />
        )}
        <footer>
            <Container>
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column textAlign="center" width={16}>
                            <Logo className="logo" />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Menu text vertical compact inverted>
                                <Menu.Item header>Get Out of Debt</Menu.Item>
                                <Menu.Item fitted><a href="/loans/">Debt Consolidation Loans</a></Menu.Item>
                                <Menu.Item fitted><a href="/debt-settlement/">Debt Settlement</a></Menu.Item>
                                <Menu.Item fitted><a href="/credit-counseling/">Credit Counseling</a></Menu.Item>
                            </Menu>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Menu text vertical compact inverted>
                                <Menu.Item header>Learn About Debt</Menu.Item>
                                <Menu.Item fitted><a href="/">Debt Consolidation</a></Menu.Item>
                                <Menu.Item fitted><a href="/how-to-get-out-of-debt/">How to Get Out of Debt</a></Menu.Item>
                                <Menu.Item fitted><a href="/debt-throughout-history/">Debt Throughout History</a></Menu.Item>
                            </Menu>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Menu text vertical compact inverted>
                                <Menu.Item header>Our Headquarters</Menu.Item>
                                <Menu.Item fitted>
                                    <address>
                                        Consolidation Resource, LLC
                                        <br />
                                        13014 N. Dale Mabry Hwy. Ste. 211
                                        <br />
                                        Tampa, FL 33618
                                    </address>
                                </Menu.Item>
                            </Menu>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Menu text vertical compact>
                                <Menu.Item header>Follow Us</Menu.Item>
                                <Menu.Item fitted>
                                    <SocialLink href="https://www.facebook.com/debtconsolidationofficial/" rel="nofollow">
                                        <CustomIcon>
                                            <FacebookSVG />
                                        </CustomIcon>
                                    </SocialLink>
                                    <SocialLink href="https://twitter.com/officialdc_com" rel="nofollow">
                                        <CustomIcon>
                                            <TwitterSVG />
                                        </CustomIcon>
                                    </SocialLink>
                                    <SocialLink href="https://www.pinterest.com/debtconsolidate/" rel="nofollow">
                                        <CustomIcon>
                                            <PinterestSVG />
                                        </CustomIcon>
                                    </SocialLink>
                                    <SocialLink href="https://www.youtube.com/channel/UCw_dwkwypdDLYRBqPh4lg5A?reload=9" rel="nofollow">
                                        <CustomIcon>
                                            <YouTubeSVG />
                                        </CustomIcon>
                                    </SocialLink>
                                </Menu.Item>
                            </Menu>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <Divider />
            <Segment basic size="mini">
                <Grid stackable columns="equal" container>
                    <Grid.Column className="copyright">
                        <List horizontal>
                            <List.Item>© {new Date().getFullYear()} Consolidation Resource, LLC</List.Item>
                            <List.Item>All rights reserved.</List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column className="compulsory-links">
                        <List horizontal divided>
                            <List.Item as="a" href="/contact/" rel="nofollow">Contact Us</List.Item>
                            <List.Item as={Link} to="/privacy-policy/#opt-out-rights" rel="nofollow">Do Not Sell My Personal Information</List.Item>
                            <List.Item as="a" href="/terms-of-use/" rel="nofollow">Terms of Use</List.Item>
                            <List.Item as="a" href="/privacy-policy/" rel="nofollow">Privacy Policy</List.Item>
                            <List.Item as="a" href="/sitemap/">Sitemap</List.Item>
                        </List>
                    </Grid.Column>
                </Grid>
            </Segment>
        </footer>
    </>
);

export default Footer;
