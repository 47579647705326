import React from 'react';
import { List } from 'semantic-ui-react';


const EditorialNote = () => {

    return (
        <List size="mini">
            <List.Item>
                <List.Content>
                    <List.Description>
                        <strong>Editorial Note:</strong> This content is not provided or commissioned by the offer provider. Opinions expressed here are author’s alone, not those of the offer provider, and have not been reviewed, approved or otherwise endorsed by the offer provider. Every reasonable effort has been made to maintain accurate information.  However, all offer information is presented without warranty. After you click on an offer you will be directed to the offer provider’s web site where you can review the terms and conditions for your offer.
                    </List.Description>
                </List.Content>
            </List.Item>
        </List>
    );
};

export default EditorialNote;