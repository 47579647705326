import PropTypes from 'prop-types';
import React from 'react';
import ReactHelmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import moment from 'moment';

const Helmet = (props) => {
    const {
        title,
        description,
        siteMetadata,
        image,
        meta: propMeta,
        canonicalUrl,
        isArticle,
        author,
        robots,
        logo,
        datePublished,
        dateModified,
    } = props;

    const siteUrl = process.env.SITE_URL
        || (typeof window !== 'undefined' && window.location && window.location.origin)
        || 'https://www.debtconsolidation.com';

    let titleTag = title;
    // Only append site name if total title tag length is <=68 characters
    const titleWithBrand = `${titleTag} - ${siteMetadata.title}`;
    if (titleWithBrand.length <= 68) {
        titleTag = titleWithBrand;
    }

    /**
     * Array of data to fill <meta> tags
     * @type {{content: string, name: string?, property: string?}[]}
     */
    const meta = [
        ...propMeta,
        { name: 'description', content: description },
        { name: 'image', content: image },
        { name: 'robots', content: robots },

        { property: 'fb:app_id', content: siteMetadata.facebook.appId },
        { property: 'og:description', content: description },
        { property: 'og:image', content: image && (image.startsWith('http') ? image : siteUrl + image) },
        { property: 'og:title', content: title },
        { property: 'og:type', content: isArticle ? 'article' : null },
        { property: 'og:url', content: canonicalUrl && (canonicalUrl.startsWith('http') ? canonicalUrl : siteUrl + canonicalUrl) },

        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:creator', content: author.twitter || siteMetadata.twitter.handle },
        { name: 'twitter:description', content: description },
        { name: 'twitter:image', content: image && (image.startsWith('http') ? image : siteUrl + image) },
        { name: 'twitter:site', content: siteMetadata.twitter.handle },
        { name: 'twitter:title', content: title },
    ].filter(({ content }) => content);

    /**
     * Array of data to fill <link> tags
     * @type {{rel: string, href: string}[]}
     */
    const link = [
        { rel: 'canonical', href: canonicalUrl && (canonicalUrl.startsWith('http') ? canonicalUrl : siteUrl + canonicalUrl) },
        { rel: 'shortcut icon', href: logo },
    ].filter(({ href }) => href);

    /**
     * Publisher data according to Schema.org
     * @type {{'@type': string, name: string, logo: {'@type': string, url: string}}}
     */
    const schemaOrgPublisher = {
        '@type': 'Organization',
        name: siteMetadata.name,
        logo: {
            '@type': 'ImageObject',
            url: siteUrl + logo, // logo must be raster, not vector
        },
    };

    /**
     * Schema.org Markup Object
     * @type {Object}
     */
    const schemaOrgJsonLd = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        url: canonicalUrl && (canonicalUrl.startsWith('http') ? canonicalUrl : siteUrl + canonicalUrl),
        name: title,
        description,
        publisher: schemaOrgPublisher,
        breadcrumb: {
            '@type': 'BreadcrumbList',
            itemListElement: [
                {
                    '@type': 'ListItem',
                    position: 1,
                    item: {
                        '@type': 'CollectionPage',
                        '@id': 'https://debtconsolidation.com',
                        name: 'debtconsolidation.com',
                        url: 'https://debtconsolidation.com',
                    },
                },
            ],
        },
    };

    if (isArticle) {
        schemaOrgJsonLd.mainEntity = {
            '@type': 'Article',
            mainEntityOfPage: {
                '@type': 'WebPage',
                '@id': canonicalUrl && (canonicalUrl.startsWith('http') ? canonicalUrl : siteUrl + canonicalUrl),
            },
            headline: title,
            name: title,
            author: {
                '@type': 'Person',
                name: author.name,
                image: siteUrl + author.image,
            },
            datePublished: moment(datePublished).format(),
            dateModified: moment(dateModified).format(),
            image: {
                '@type': 'ImageObject',
                representativeOfPage: true,
                url: image,
            },
            description,
            publisher: schemaOrgPublisher,
        };
    }

    return (
        <ReactHelmet
            title={titleTag}
            meta={meta}
            link={link}
        >
            <script type="application/ld+json">{JSON.stringify(schemaOrgJsonLd)}</script>
        </ReactHelmet>
    );
};

Helmet.propTypes = {
    canonicalUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
    isArticle: PropTypes.bool,
    robots: PropTypes.string,
    siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
    author: PropTypes.shape({
        name: PropTypes.string,
        twitter: PropTypes.string,
    }),
    meta: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        property: PropTypes.string,
        content: PropTypes.string,
    })),
    product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        image: PropTypes.oneOfType([ // A single image url or an array of image urls
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        description: PropTypes.string,
        brand: PropTypes.string,
    }),
    logo: PropTypes.string.isRequired,
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
};

Helmet.defaultProps = {
    author: {},
    canonicalUrl: typeof window !== 'undefined' && `${window.location.origin}${window.location.pathname}`,
    image: null,
    isArticle: false,
    meta: [],
    product: null,
    robots: null,
    datePublished: null,
    dateModified: null,
};

export default props => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        name
                        facebook {
                            appId
                            page
                        }
                        twitter {
                            handle
                        }
                        youtube {
                            channel
                        }
                    }
                }
                siteCover: file(
                    relativePath: {
                        eq: "site-cover.jpg"
                    }
                ) {
                    image: publicURL
                }
                logo: file(
                    relativePath: {
                        eq: "favicon.png"
                    }
                ) {
                    logo: publicURL
                }
            }
        `}
        render={data => <Helmet {...data.logo} {...data.siteCover} {...data.site} {...props} />}
    />
);
