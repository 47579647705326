import React from 'react';
import { List } from 'semantic-ui-react';

const AdvertiserDisclosure = () => {

    return (
        <List size="mini">
            <List.Item>
                <List.Content>
                    <List.Description>
                        <strong>Advertiser Disclosure:</strong> DebtConsolidation.com is a free online resource that offers valuable content and comparison services to users. To keep this resource 100% free, we receive compensation from many of the offers listed on the site. This compensation may impact how and where products appear across the site (including, for example, the order in which they appear). DebtConsolidation.com does not provide access to all available product offerings. Editorial opinions expressed on the site are strictly our own and are not provided, endorsed, or approved by advertisers.
                    </List.Description>
                    <List.Description>
                        The Operator of this Website is not a Financial Advisor and does not provide debt settlement, debt consolidation, or credit counseling services. This website does not constitute an offer to settle your debt or negotiate a debt settlement. This website will submit the information you provide to third party financial services providers specializing in providing credit counseling, debt settlement, and debt consolidation services. Providing your information on this website does not guarantee that you will be eligible to consolidate or settle your debt, or that you will be offered credit counseling services. Offers and services may vary between financial services providers and based on your individual financial circumstances. For details, questions or concerns, please contact your financial services provider directly.
                    </List.Description>
                    <List.Description>
                        Credit checks, consumer reports, and other personal data may be obtained by some service providers from Experian, Equifax, Trans Union, or through alternate providers.
                    </List.Description>
                </List.Content>
            </List.Item>
        </List>
    );
};

export default AdvertiserDisclosure;