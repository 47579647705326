import React, { Component } from 'react';
import { Container, Grid, Icon, Menu, Segment } from 'semantic-ui-react';

import Logo from '!svg-react-loader!../../images/logos/logo-debtconsolidation.svg?name=Logo';

import links from './links';
import shortid from 'shortid';

export default class MegaMenuDesktop extends Component {

    constructor(props) {
        super(props);
        this.state = { activeItem: 'default' };
        this.node = null;
    }

    componentWillMount() {
        if (typeof document !== 'object') {
            return;
        }

        document.addEventListener('mousedown', this.handleClick.bind(this), false);
    }

    componentWillUnmount() {
        if (typeof document !== 'object') {
            return;
        }

        document.removeEventListener('mousedown', this.handleClick.bind(this), false);
    }

    handleItemClick(e, { name }) {
        this.setState({ activeItem: this.state.activeItem === name ? 'default' : name });
    }

    handleClick(e) {
        if (!this.node || this.node.contains(e.target)) {
            return;
        }

        this.setState({ activeItem: 'default' });
    }

    render() {
        const { activeItem } = this.state;

        return (
            <div ref={(node) => { this.node = node }}>
                <Container className="megamenu__desktop">
                    <Menu attached='top' tabular className="megamenu">
                        <Menu.Item className="logo">
                            <a href="/" title="Home"><Logo /></a>
                        </Menu.Item>

                        {links.map(link => {
                            return <Menu.Item
                                key={shortid.generate()}
                                name={link.heading}
                                active={activeItem === link.heading}
                                onClick={this.handleItemClick.bind(this)}
                            >
                                {link.heading} <Icon name={`caret ${activeItem === link.heading ? 'up' : 'down'}`} />
                            </Menu.Item>
                        })}
                        <Menu.Item className='phonenumber' position='right'>
                            <a href="tel:+18668920226">Talk to a Debt Pro 866-892-0226</a>
                        </Menu.Item>
                    </Menu>

                    {links.map(link => {
                        if (activeItem === link.heading) {
                            return (
                                <Segment className="megamenu__segment" key={shortid.generate()}>
                                    <Container>
                                        <Grid columns={link.subsections.length} divided>
                                            <Grid.Row>
                                                {link.subsections.map(section => (
                                                    <>
                                                        {section.heading ? (
                                                            <Grid.Column>
                                                                <div className="megamenu-item__title ">{section.heading}</div>
                                                                <div className="megamenu-item__content ">
                                                                    <ul className={`megamenu-item__list megamenu-item__list__${section.columns ? section.columns : '1'}col list`}>
                                                                        {section.links ? section.links.map((item, index) => (
                                                                            <li key={index}><a href={item.href} {... (item.hasOwnProperty("rel") ? {rel: item.rel} : {})}>{item.name}</a></li>
                                                                        )) : ''}
                                                                        {section.button ? (
                                                                            <li><a href={section.button.href} class="ui yellow button more" role="button">{section.button.name}</a></li>
                                                                        ) : ''}
                                                                    </ul>
                                                                </div>
                                                            </Grid.Column>
                                                        ) : ''}
                                                        {section.content ? (
                                                            <Grid.Column>
                                                                <div className="megamenu-item__content ">
                                                                    {section.content}
                                                                </div>
                                                            </Grid.Column>
                                                        ) : ''}
                                                    </>
                                                ))}

                                            </Grid.Row>

                                            {link.footer && link.footer.content ? (
                                                link.footer.content
                                            ) : ''}

                                        </Grid>
                                    </Container>
                                </Segment>
                            );
                        }
                    })}
                </Container>
            </div>
        );
    }
}
