import React from 'react';
import Logo from '!svg-react-loader!../../images/logos/logo-debtconsolidation.svg?name=Logo';
import FeaturedArticleImage from '../../images/whitepapers/coin.jpg';

const menuFeaturedArticle = (
    <div className="article-block">
        <div className="article-block__item article-block__item--single article-block__item--seamless">
            <div className="article-block__image-wrapper">
                <a title="Read article: Understanding Debt Consolidation: What It Does and How It Can Help" href="/understanding-debt-consolidation/">
                    <img
                        src={FeaturedArticleImage}
                        alt="Featured image: Understanding Debt Consolidation: What It Does and How It Can Help"
                        className="article-block__image"
                    />
                </a>
            </div>
            <div className="article-block__content">
                <div className="article-block__title">
                    <a title="Read article: Understanding Debt Consolidation: What It Does and How It Can Help" href="/understanding-debt-consolidation/">
                        Understanding Debt Consolidation: What It Does and How It Can Help
                    </a>
                </div>
                <div className="article-block__excerpt">
                Millions of Americans are troubled with crippling amounts of debt every day. Learn more about how this debt can be overcome with the right strategy and consolidation.
                </div>
                <div className="article-block__more-link">
                    <a title="Read article: Understanding Debt Consolidation: What It Does and How It Can Help" href="/understanding-debt-consolidation/">
                        Read More
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default [
    {
        heading: 'Get Out of Debt',
        subsections: [
            {
                heading: 'Debt Relief Options',
                links: [
                    {
                        name: 'Debt Consolidation Loans',
                        href: '/loans/',
                    },
                    {
                        name: 'Debt Settlement',
                        href: '/debt-settlement/',
                    },
                    {
                        name: 'Credit Counseling',
                        href: '/credit-counseling/',
                    }
                ],
            },
            {
                heading: 'Helpful Resources',
                links: [
                    {
                        name: 'How to Get Out of Debt',
                        href: '/how-to-get-out-of-debt/',
                    },

                    {
                        name: 'Free Debt Analysis',
                        href: '/loans/#get-started',
                    }
                ],
            },
            {
                heading: 'Reviews',
                links: [
                    {
                        name: 'Family Budget Services',
                        href: '/credit-counseling/family-budget-services-review/',
                    },
                    {
                        name: 'Freedom Debt Relief',
                        href: '/debt-settlement/freedom-debt-relief/',
                    },
                    {
                        name: 'DebtMD',
                        href: '/debt-settlement/debtmd/',
                    },
                    {
                        name: 'eCredit Advisor',
                        href: '/credit-counseling/ecredit-advisor-review/',
                    },
                    {
                        name: 'Accredited Debt Relief',
                        href: '/debt-settlement/accredited-debt-relief-review/',
                    }
                ],
            },
            {
                content: menuFeaturedArticle,
            },
        ],
    },
    {
        heading: 'Learn About Debt',
        subsections: [
            {
                heading: 'Guides to',
                links: [
                    {
                        name: 'Bankruptcy',
                        href: '/bankruptcy/',
                    },
                    {
                        name: 'Budgeting',
                        href: '/budgeting/',
                    },
                    {
                        name: 'Credit Card Debt',
                        href: '/credit-card-debt/',
                    },
                    {
                        name: 'Debt Management',
                        href: '/debt-management/',
                    }
                ],
            },
            {
                heading: 'Learn More',
                links: [
                    {
                        name: '6 Step to Getting Out of Debt',
                        href: '/6-steps-out-of-debt/',
                    },
                    {
                        name: 'How to Stop Debt Collector Calls',
                        href: '/stop-debt-collector-calls/',
                    }
                ],
            },
            {
                content: menuFeaturedArticle,
            },
        ],
    },
    {
        heading: 'About Us',
        subsections: [
            {
                heading: 'Company Info',
                links: [
                    {
                        name: 'About Us',
                        href: '/about/',
                        rel: 'nofollow',
                    }
                ],
            },
            {
                content: (
                    <div className="logo">
                        <Logo/>
                        <p>Find the resources, tools and relief programs to help you manage your finances and get out of debt once and for all.</p>
                    </div>
                ),
            },
        ],
    },
];
